.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  &.report-pdf {
    justify-content: start;
  }
}

body {
  min-width: 320px;
}

.header,
.footer {
  min-width: 767px;
}

@media (max-width: 767px) {
  .header-wrapper,
  .footer-wrapper {
    overflow: auto;
  }
  .header,
  .footer {
    min-width: 100%;
  }
}

.report-pdf {
  .header-wrapper {
    margin-bottom: 0rem;

    .tool-header__bottom {
      min-height: auto;
      padding: 10;
    }
  }
  .footer-wrapper {
    display: none;
  }
  .control-bar {
    display: none;
  }
}
